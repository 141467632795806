import React, { useState, useEffect } from "react";
import { Modal, Input, message, Select, Switch, Skeleton, Spin } from "antd";
import { Link } from "react-router-dom";
import { DeleteIcon, Pen } from "../../assets/images/icons";
import "./product-card.scss";
import { useUserContext } from "../../context/dashboard-context";
import axios from "axios";
import { Image } from "antd";
import Spinner from "../loading";
import ImageEditeUploader from "../imageEditeUploader";
const { Option } = Select;

const ProductCard = ({
  img,
  description,
  title,
  id,
  excerpt,
  type,
  price,
  featuredproduct,
  data,
  GetProducts,
  category,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState(title);
  const [updatedBrand, setUpdatedBrand] = useState(data?.[0]?.value);
  const [updatedTitleRu, setUpdatedTitleRu] = useState(data?.[1]?.value);
  const [featured, setFeatured] = useState(featuredproduct);
  const [switchbutton, setSwitch] = useState(excerpt);
  const [updatedDescription, setUpdatedDescription] = useState(description);
  const [updatedDescriptionru, setUpdatedDescriptionru] = useState(
    data?.[2]?.value
  );
  const [updatedImage, setUpdatedImage] = useState(img);
  const [updatedPrice, setUpdatedPrice] = useState(0);
  const [categoryId, setCategoryId] = useState(category);
  const [imageFile, setImageFile] = useState(null);
  // const [updatedExcerpt, setUpdatedExcerpt] = useState(excerpt);
  const {
    token,
    // GetCategory,
    images,
    brands,
    GetNews,
    GetBrands,
  } = useUserContext();
  console.log(data);

  const [categorys, setCategorys] = useState();
  const GetCategory = () => {
    setLoading(true);
    axios
      .get(
        `https://api.protools.uz/v1/categories?limit=12?sortBy=createdAt&orderBy=DESC`,
        {}
      )
      .then((response) => {
        console.log(response, "misha");
        setLoading(false);
        setCategorys(response?.data?.data);
      });
  };
  // useEffect(() => {
  //   if (isEditMode && categorys && categorys.length > 0) {
  //     setCategoryId(categorys[0]._id);
  //   }
  // }, [isEditMode, categorys]);
  useEffect(() => {
    if (isEditMode) {
      GetCategory();
    }
  }, [isEditMode]);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const showEditForm = () => {
    setIsEditMode(true);
    showModal();
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
  };

  const handleCancel = () => {
    setUpdatedImage(null);
    setIsModalOpen(false);
    setIsEditMode(false);
  };

  const removeProduct = () => {
    axios
      .delete(`https://api.protools.uz/v1/${type}/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        GetCategory();
        GetProducts();
        // window.location.reload();
        handleOk();
        message.success("Mahsulot o`chirildi");
      })
      .catch((err) => {
        console.error(err);
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("token");
        }
        message.error("Mahsulot o`chirilmadi");
      });
  };
  const [loading, setLoading] = useState(false);
  const getBrandImage = (brand) => {
    const brandImage =
      brands.find((element) => element.name === brand)?.image || "";
    return brandImage;
  };
  const updateProduct = () => {
    setLoading(true);
    axios
      .put(
        `https://api.protools.uz/v1/${type}/${id}`,
        {
          ...(type === "brands"
            ? { name: updatedTitle ? updatedTitle : title }
            : {}),
          title: updatedTitle ? updatedTitle : title,
          description: updatedDescription ? updatedDescription : description,
          price: updatedPrice ? parseFloat(updatedPrice) : price,
          category: categoryId,
          images: updatedImage ? [updatedImage] : [img], // Keep the existing images
          excerpt: switchbutton === true ? "true" : "false",
          featured: featured ? featured : false,
          status: featured === true ? "ACTIVE" : "INACTIVE",
          additionalInfos: [
            { key: "brand", value: updatedBrand },
            { key: "titleRu", value: updatedTitleRu },
            { key: "descriptionRu", value: updatedDescriptionru },
            { key: "brandimage", value: getBrandImage(updatedBrand) },
          ],
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        GetCategory();
        GetProducts();
        GetNews();
        handleOk();
        setLoading(false);
        message.success("Mahsulot yangilandi");
        setUpdatedTitle("");
        setUpdatedDescription("");
        setUpdatedPrice(0);
        setUpdatedImage(null);
        setImageFile(null);
      })
      .catch((err) => {
        console.error(err);
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("token");
        }
        setLoading(false);
        // window.location.reload()
        // message.error("Mahsulot yangilanmadi");
      });
  };
  const [fuckingPrice, setFuckingPrice] = useState(price);
  return (
    <>
      {
        // loading && <Spinner />
      }
      {/* <div className="product-card"> */}
      {/* <div className="card-img"> */}
      {/* <img src={img} alt="" /> */}
      <td>{title}</td>
      <td>
        {description?.length > 80
          ? description.slice(0, 30) + "..."
          : description}
      </td>
      <td>
        {" "}
        {price === 0 ? " " : new Intl.NumberFormat().format(price) + " so'm"}
      </td>
      <td>
        <Image width={80} height={40} src={img} />
        {/* <Image width={80} height={40} src={data[3]?.value} /> */}
      </td>
      <td>
        <Spin spinning={loading}>
          <button
            style={{
              border: "none",
              outline: "none",
              background: "transparent",
            }}
            onClick={showEditForm}
          >
            <Pen />
            {/* O’zgartirish */}
          </button>
          <button
            style={{
              border: "none",
              outline: "none",
              background: "transparent",
            }}
            onClick={removeProduct}
          >
            <DeleteIcon />
            {/* O’chirish */}
          </button>
        </Spin>
      </td>
      {/* </div> */}
      {/* </div> */}
      <Modal
        title={isEditMode ? "Update Product" : "Delete Product"}
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        {isEditMode ? (
          <div className="product_edit_card">
            <p className="product_edit_card_info">
              {(() => {
                switch (type) {
                  case "products":
                    return "Mahsulotlarni";
                  case "brands":
                    return "Brandni";
                  case "categories":
                    return "Kategoriyani";
                  case "articles":
                    return "Yangilikni";
                  default:
                    return "o'zgartirish";
                }
              })()}{" "}
              o'zgartirish
            </p>

            {type === "brands" ? (
              <>
                {/* {loader ? (
                <Skeleton.Image active={loader} />
              ) : (
                <img
                className="imageStyle"
                  src={updatedImage ? updatedImage : img}
                  alt="card image"
                />
              )}
              <label className="product_edit_card_input_label" htmlFor="img">
                Boshqa rasm yuklash
                <input
                  onChange={handleImageChange}
                  type="file"
                  name="img"
                  id="img"
                  className="product_edit_card_input"
                  accept="image/png, image/jpeg image/jpg"
                />
              </label> */}
                {/* <label
                  className="product_edit_card_input_label"
                  htmlFor="img"
                  style={{
                    backgroundImage: `url(${
                      updatedImage ? updatedImage : img
                    })`,
                  }}
                >
                  <input
                    onChange={handleImageChange}
                    type="file"
                    name="img"
                    id="img"
                    className="product_edit_card_input"
                  />
                  <div className="upload-icon">
                    <svg
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21 15V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V15M17 8L12 3M12 3L7 8M12 3V15"
                        stroke="white"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    <p>Boshqa rasm yuklash</p>
                  </div>
                </label> */}
                <label htmlFor="title" className="product_edit_card_title">
                  Brand Nomi
                  <Input
                    id="title"
                    name="title"
                    placeholder="Brand Name"
                    value={updatedTitle}
                    onChange={(e) => setUpdatedTitle(e.target.value)}
                    style={{ marginBottom: "16px" }}
                    required
                  />
                </label>
              </>
            ) : (
              <>
                <ImageEditeUploader
                  setUpdatedImage={setUpdatedImage}
                  updatedImage={updatedImage ? updatedImage : img}
                  token={token}
                />
                <div className="my-3 d-flex justify-content-between">
                  <label>
                    {" "}
                    Top {type === "products" ? "mahsulot" : "Category"}
                  </label>
                  <Switch
                    onChange={() => setFeatured(!featured)}
                    checked={featured}
                  />
                </div>
                <div className="my-3 d-flex justify-content-between">
                  <label> Sotuvda mavjud</label>
                  <Switch
                    onClick={() =>
                      setSwitch(switchbutton === "true" ? false : true)
                    }
                    defaultChecked={switchbutton == "true" ? true : false}
                  />
                </div>
                <label htmlFor="title" className="product_edit_card_title">
                  Nomi uz
                  <Input
                    id="title"
                    name="title"
                    placeholder="Title"
                    value={updatedTitle?.value}
                    defaultValue={title}
                    onChange={(e) => setUpdatedTitle(e.target.value)}
                    style={{ marginBottom: "16px" }}
                    required
                  />
                </label>
                <label htmlFor="title" className="product_edit_card_title">
                  Nomi ru
                  <Input
                    id="title"
                    name="title"
                    placeholder="Title"
                    value={updatedTitleRu}
                    onChange={(e) => setUpdatedTitleRu(e.target.value)}
                    style={{ marginBottom: "16px" }}
                    required
                  />
                </label>
                {/* <label htmlFor="title" className="product_edit_card_title">
                  Link
                  <Input
                    id="excerpt"
                    name="excerpt"
                    placeholder="Link"
                    value={updatedExcerpt}
                    onChange={(e) => setUpdatedExcerpt(e.target.value)}
                    style={{ marginBottom: "16px" }}
                    required
                  />
                </label> */}
                {type === "products" && (
                  <label htmlFor="price" className="product_edit_card_title">
                    Narxi
                    <Input
                      id="price"
                      name="price"
                      placeholder="Price"
                      type="text"
                      value={updatedPrice ? updatedPrice : fuckingPrice}
                      onChange={(e) => {
                        setUpdatedPrice(e.target.value);
                        setFuckingPrice(e.target.value);
                      }}
                      style={{ marginBottom: "16px" }}
                      // required
                    />
                  </label>
                )}
                <div>
                  <label htmlFor="">Brend</label>
                  <select
                    required
                    id="brand"
                    value={updatedBrand}
                    onChange={(e) => setUpdatedBrand(e.target.value)}
                    style={{
                      marginBottom: "16px",
                      width: "100%",
                      padding: "12px 8px",
                      borderRadius: "8px",
                    }}
                  >
                    {brands?.map((i) => (
                      <option key={i._id} value={i.name}>
                        {i.name}
                      </option>
                    ))}
                  </select>
                </div>
                {type === "products" && (
                  <div>
                    <label htmlFor="">Kategoriyala</label>
                    <select
                      required
                      id="category"
                      value={categoryId}
                      onChange={(e) => setCategoryId(e.target.value)}
                      style={{
                        marginBottom: "16px",
                        width: "100%",
                        padding: "12px 8px",
                        borderRadius: "8px",
                      }}
                    >
                      {categorys?.map((cat) => (
                        <option key={cat._id} value={cat._id}>
                          {cat.title}
                        </option>
                      ))}
                    </select>
                  </div>
                )}
                <label
                  htmlFor="description"
                  className="product_edit_card_title"
                >
                  Matn
                  <Input.TextArea
                    id="description"
                    name="description"
                    placeholder="Description"
                    value={updatedDescription}
                    onChange={(e) => setUpdatedDescription(e.target.value)}
                    style={{ marginBottom: "16px", resize: "none" }}
                    required
                  />
                </label>
                <label
                  htmlFor="description"
                  className="product_edit_card_title"
                >
                  Matn ru
                  <Input.TextArea
                    id="descriptionru"
                    name="descriptionru"
                    placeholder="Description"
                    value={updatedDescriptionru}
                    onChange={(e) => setUpdatedDescriptionru(e.target.value)}
                    style={{ marginBottom: "16px", resize: "none" }}
                    required
                  />
                </label>
              </>
            )}
            <div className="modal-footer">
              <button onClick={handleCancel}>
                <span>Bekor qilish</span>
              </button>
              <button
                className="product_edit_card_save"
                onClick={updateProduct}
              >
                <span>Saqlash</span>
              </button>
            </div>
          </div>
        ) : (
          <div>
            <p>Siz rostdan ham ushbu mahsulotni o’chirmoqchimisiz?</p>
            <div className="modal-footer">
              <button onClick={handleCancel}>
                <span>Bekor qilish</span>
              </button>
              <button className="delete-btn" onClick={removeProduct}>
                <span>O'chirish</span>
              </button>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ProductCard;
