import "./news-page.scss";
import { useContext, useEffect, useState } from "react";
import { Form, Input, Modal, Pagination, Select, Switch, Checkbox } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ProductCard from "../components/product-card";
import { useUserContext } from "../context/dashboard-context";
import axios from "axios";
import ImageUploader from "../components/uploader";
import SearchInput from "../components/search";
import Table from "react-bootstrap/Table";
import CustomPagination from "../components/pagination";
import formatPrice from "../utills";
import ZapchastCard from "../components/product-card/zapchast-card";
import ImageUploadContext, {
  useImageUpload,
} from "../context/ImageUploadContext";
const ZapchastPage = () => {
  const [form] = Form.useForm();
  const {
    token,
    brands,
    zapchastproducts,
    setZapchastProducts,
    setFileList,
    fileList,
  } = useUserContext();
  // const { clearImages, images } = useContext(ImageUploadContext);
  const { addImage, clearImages, images } = useImageUpload();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    JSON.parse(localStorage.getItem("page")),
    1
  );
  const [zapchastImage, setZapchastImage] = useState();
  const zapchastImageChanger = (e) => {
    setZapchastImage(e?.target?.files?.[0]);
  };
  const [totalProducts, setTotalProducts] = useState(0);
  const [pagesCount, setPagesCount] = useState(0);
  const [brandImg, setBrandImg] = useState();

  const GetProducts = () => {
    setLoading(true);
    axios
      .get(`https://back2.protools.uz/api/maxsulot/?include=zapchast`, {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        setLoading(false);
        setZapchastProducts(response?.data?.data);
        setTotalProducts(response?.data?.totalCount);
        setPagesCount(response?.data?.pagesCount);
      });
  };
  const [zapchast, setZapchast] = useState();
  const GetZapchastList = () => {
    setLoading(true);
    axios
      .get(`https://back2.protools.uz/api/zapchast/?per_page=10`, {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // setClear(false)
        setLoading(false);
        setZapchast(response?.data?.data);
        setTotalProducts(response?.data?.totalCount);
        setPagesCount(response?.data?.pagesCount);
      });
  };
  useEffect(() => {
    if (isModalOpen) {
      GetZapchastList();
    }
  }, [isModalOpen]);
  useEffect(() => {
    GetProducts();
  }, []);

  const [img, setImg] = useState([]);
  const [title, setTitle] = useState("");
  const [titleRu, setTitleRu] = useState("");
  const [desc, setDec] = useState("");
  const [descRu, setDecRu] = useState("");
  const [excerpt, setExcerpt] = useState(false);
  const [price, setPrice] = useState("");
  const [categoryId, setCategory] = useState();
  const [loading, setLoading] = useState(false);
  const [brandState, setBrandState] = useState();
  const [switchbutton, setSwitch] = useState(excerpt);
  useEffect(() => {
    if (brandState) {
      const selectedBrand = brands.find(
        (brand) => brand.name === selectBoxValue
      );
      if (selectedBrand) {
        setBrandImg(selectedBrand.image);
      }
    }
  }, [brandState]);

  const handlePageChange = (page) => {
    console.log(page, "page");
    setCurrentPage(page);
    localStorage.setItem("page", JSON.stringify(page));
    // Fetch products for the new page
    GetProducts(page);
  };

  const showModal = () => {
    setIsModalOpen(true);
    // GetCategory();
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const [clear, setClear] = useState(false);
  const handleCancel = () => {
    clearImages();
    setIsModalOpen(false);
    setClear(true);
    form.resetFields();
    console.log("reset");
    // GetProducts();
    // window.location.reload()
  };

  const getBrandImage = async (brand) => {
    const brandImage =
      brands.filter((element) => element.name === brand)?.[0].image ?? "";
    return brandImage;
  };
  const onFinish = async () => {
    const formData = new FormData();
    formData.append("zapchast_id", categoryId);
    formData.append("name_uz", title);
    formData.append("name_ru", titleRu);
    formData.append("description", desc);
    formData.append("price", price);
    formData.append("brand", brandState);
    formData.append("description_ru", descRu);

    // Assuming zapchastImage and brandImage are File objects
    formData.append("image", images[0]); // Append image file directly

    // If brandImage is a File object, append it as well

    formData.append("brand_image", brandImage);

    axios
      .post("https://back2.protools.uz/api/maxsulot/", formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data", // Change content type to multipart/form-data
        },
      })
      .then((response) => {
        console.log("Product added successfully:", response.data);
        GetProducts();
        handleOk();
        form.resetFields();
        // setClear(true)
        // setImages([])
        setFileList([]);
        clearImages();
        setLoading(true);
      })
      .catch((error) => {
        console.error("Error adding product:", error);
      });
  };

  const [selectBoxValue, setSelectBoxValue] = useState();
  const [avaibleSale, setAvaibleSale] = useState(false);
  console.log(totalProducts, "totalProducts");
  const GetSearch = (value) => {
    console.log(value, "value");
    axios
      .get(`https://api.protools.uz/v1/products?search=${value}`)
      .then((response) => {
        setZapchastProducts(response?.data?.data);
        setTotalProducts(response?.data?.totalCount);
      });
  };
  const [brandImage, setBrandImage] = useState();
  console.log(brandImage, "brandImage");
  const handleBrandchange = (value) => {
    console.log(JSON.parse(value).image, "valueee");
    setBrandImage(JSON.parse(value).image);
    setBrandState(JSON.parse(value).name);
  };
  console.log(zapchastproducts);
  return (
    <>
      <div className="news-page">
        <div className="page-top">
          <h1>Mahsulotlar</h1>
          <button onClick={showModal}>+ Mahsulot qo’shish</button>
        </div>
        <Table>
          <thead>
            <tr>
              <th>Nomi</th>
              <th>Tafsifi</th>
              <th>Narxi</th>
              <th>Rasm</th>
              <th>Tahrirlash</th>
            </tr>
          </thead>
          {avaibleSale ? (
            <tbody>
              {zapchastproducts
                ?.filter((item) => item.excerpt === "true")
                ?.map((i, k) => (
                  <tr>
                    <ZapchastCard
                      featuredproduct={i?.featured}
                      data={i?.additionalInfos}
                      zapchast_id={i?.zapchast_id}
                      maxsulot_id={i?.zapchast_id}
                      id={i?.id}
                      type={"products"}
                      img={i?.image}
                      title_ru={i?.name_ru}
                      title={i.title}
                      brand={i?.brand}
                      description={i.description}
                      description_ru={i?.description_ru}
                      price={i?.price}
                      status={i.status}
                    />
                  </tr>
                ))}
            </tbody>
          ) : (
            <tbody>
              {selectBoxValue
                ? zapchastproducts
                    .filter(
                      (item) =>
                        item.additionalInfos?.[0]?.value === selectBoxValue
                    )
                    ?.map((i, k) => (
                      <tr>
                        <ZapchastCard
                          featuredproduct={i?.featured}
                          title_ru={i?.name_ru}
                          zapchast_id={i?.zapchast_id}
                          id={i?.id}
                          maxsulot_id={i?.zapchast_id}
                          brand={i?.brand}
                          type={"products"}
                          status={i.status}
                          img={i?.image}
                          description_ru={i?.description_ru}
                          title={i.name_uz}
                          description={i.description}
                          price={i?.price}
                        />
                      </tr>
                    ))
                : zapchastproducts?.map((i, k) => (
                    <tr>
                      <ZapchastCard
                        featuredproduct={i?.featured}
                        zapchast_id={i?.zapchast_id}
                        data={i?.additionalInfos}
                        id={i?.id}
                        type={"products"}
                        img={i?.image}
                        title={i.name_uz}
                        brand={i?.brand}
                        status={i.status}
                        title_ru={i?.name_ru}
                        description={i.description}
                        description_ru={i?.description_ru}
                        price={i?.price}
                      />
                    </tr>
                  ))}
            </tbody>
          )}
        </Table>

        <CustomPagination
          setCurrentPage={setCurrentPage}
          current={currentPage}
          // pageSize={itemsPerPage}
          func={GetProducts}
          defaultCurrent={1}
          total={totalProducts}
          onChange={handlePageChange}
        />
      </div>
      <div className="modal-news">
        <Modal
          title="Mahsulot qo’shish"
          open={isModalOpen}
          onCancel={handleCancel}
        >
          <div className="modal-body">
            <div className="body-img">
              <Form
                form={form}
                layout="vertical"
                onFinish={() => {
                  onFinish();
                }}
              >
                <div>
                  <ImageUploader
                    required={true}
                    clear={clear}
                    setClear={setClear}
                  />
                </div>
                <div className="my-3 d-flex justify-content-between">
                  <label> Sotuvda mavjud</label>
                  <Switch onClick={() => setExcerpt(!excerpt)} />
                </div>
                <Form.Item
                  label="Nomi uz"
                  name="title"
                  rules={[{ required: true, message: "Title is required" }]}
                >
                  <Input
                    placeholder="Nomi"
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="Nomi ru"
                  name="titleru"
                  rules={[{ required: true, message: "TitleRu is required" }]}
                >
                  <Input
                    placeholder="Nomi"
                    onChange={(e) => setTitleRu(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="Tafsifi uz"
                  name="description"
                  rules={[
                    { required: true, message: "Description is required" },
                  ]}
                >
                  <Input
                    placeholder="Tafsifi"
                    onChange={(e) => setDec(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="Tafsifi ru"
                  name="descriptionru"
                  rules={[
                    { required: true, message: "Descriptionru is required" },
                  ]}
                >
                  <Input
                    placeholder="Tafsifi uz"
                    onChange={(e) => setDecRu(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="Narxi"
                  name="price"
                  // rules={[{ required: true, message: "Price is required" }]}
                >
                  <Input
                    type="text"
                    placeholder="Narxi"
                    value={formatPrice(price)}
                    onChange={(e) => setPrice(e.target.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="Brendlar"
                  name="brand"
                  rules={[{ required: true, message: "Brendlar is required" }]}
                >
                  <Select
                    placeholder="Brand tanlash"
                    onChange={(value) => {
                      handleBrandchange(value);
                      console.log(value, "valuee");
                    }}
                  >
                    {brands?.map((i, k) => (
                      <Select.Option
                        dataImage={i.image}
                        key={i.id}
                        value={JSON.stringify({ name: i.name, image: i.image })}
                      >
                        {i.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {/* {
                      data.filter(item => item.additionalInfos[0].value === selectBoxValue).map()
                    } */}
                <Form.Item
                  label="Zapchast"
                  name="zapchast"
                  rules={[{ required: true, message: "Zapchast is required" }]}
                >
                  <Select
                    placeholder="Zapchast tanlash"
                    onChange={(value) => setCategory(value)}
                  >
                    {zapchast?.map((i, k) => (
                      <Select.Option key={i.id} value={i.id}>
                        {i.name_uz}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>

                <div className="modal-footer">
                  <button type="button" onClick={handleCancel}>
                    <span>Bekor qilish</span>
                  </button>
                  <button disabled={images.length < 1}>
                    <span>Qo'shish</span>
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default ZapchastPage;
