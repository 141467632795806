import React, { useState, useEffect } from "react";
import { Modal, Input, message, Skeleton, Spin } from "antd";
import { Link } from "react-router-dom";
import { DeleteIcon, Pen } from "../../assets/images/icons";
import "./product-card.scss";
import { useUserContext } from "../../context/dashboard-context";
import axios from "axios";
import ImageEditeUploader from "../imageEditeUploader";

const { TextArea } = Input;

const NewsCard = ({ data, GetNews }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState(data.title);
  const [updatedDescription, setUpdatedDescription] = useState(data.body);
  const [excerpt, setExcerpt] = useState(data.excerpt);
  const [author, setAuthor] = useState(data.author);
  const [updatedImage, setUpdatedImage] = useState(data.cardImage);
  const [loading, setLoading] = useState(false);

  const { token, categorys } = useUserContext();

  useEffect(() => {
    if (isEditMode) {
      setUpdatedTitle(data.title);
      setUpdatedDescription(data.body);
      setExcerpt(data.excerpt);
      setAuthor(data.author);
      setUpdatedImage(data.cardImage);
    }
  }, [isEditMode, data]);

  const showModal = () => setIsModalOpen(true);
  const showEditForm = () => {
    setIsEditMode(true);
    showModal();
  };
  const handleOk = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
  };
  const removeProduct = () => {
    setLoading(true);
    axios
      .delete(`https://api.protools.uz/v1/articles/${data?._id}`, {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        GetNews();
        setLoading(false);
        message.success("Mahsulot o`chirildi");
      })
      .catch((err) => {
        console.error(err);
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("token");
        }
        message.error("Yangilik o`chirilmadi");
        setLoading(false);
      });
  };

  const updateProduct = () => {
    setLoading(true);
    axios
      .put(
        `https://api.protools.uz/v1/articles/${data?._id}`,
        {
          title: updatedTitle,
          body: updatedDescription,
          author: author,
          excerpt: excerpt,
          cardImage: updatedImage,
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        GetNews();
        handleOk();
        message.success("Mahsulot yangilandi");
        resetForm();
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("token");
        }
        message.error("Mahsulot yangilanmadi");
        setLoading(false);
      });
  };

  const resetForm = () => {
    setUpdatedTitle("");
    setUpdatedDescription("");
    setUpdatedImage(null);
  };
  return (
    <>
      <Spin spinning={loading}>
        <div className="product-card">
          <div className="card-img">
            <img src={data?.cardImage} alt="" />
            <div className="card-edit">
              <button onClick={showEditForm}>
                <Pen />
                O’zgartirish
              </button>
              <button onClick={removeProduct}>
                <DeleteIcon />
                O’chirish
              </button>
            </div>
          </div>
          <Link className="card-text">
            <h1>{data?.title}</h1>
            <p>{data?.description}</p>
          </Link>
        </div>
      </Spin>
      <Modal
        title={isEditMode ? "Update Product" : "Delete Product"}
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Spin spinning={loading}>
          {isEditMode ? (
            <div className="product_edit_card">
              <p className="product_edit_card_info">Yangilikni o'zgartirish</p>
              <>
                <ImageEditeUploader
                  setUpdatedImage={setUpdatedImage}
                  updatedImage={updatedImage}
                  token={token}
                />
                <label htmlFor="title" className="product_edit_card_title">
                  Nomi uz
                  <Input
                    id="title"
                    name="title"
                    placeholder="Nomi"
                    value={updatedTitle}
                    onChange={(e) => setUpdatedTitle(e.target.value)}
                    style={{ marginBottom: "16px" }}
                    required
                  />
                </label>

                <label
                  htmlFor="description"
                  className="product_edit_card_title"
                >
                  Matn uz
                  <TextArea
                    id="description"
                    name="description"
                    placeholder="Description"
                    value={updatedDescription}
                    onChange={(e) => setUpdatedDescription(e.target.value)}
                    style={{ marginBottom: "16px", resize: "none" }}
                    required
                  />
                </label>
                <label
                  htmlFor="description"
                  className="product_edit_card_title"
                >
                  Matn ru
                  <TextArea
                    id="description"
                    name="description"
                    placeholder="Description"
                    value={author}
                    onChange={(e) => setAuthor(e.target.value)}
                    style={{ marginBottom: "16px", resize: "none" }}
                    required
                  />
                </label>
                <label
                  htmlFor="description"
                  className="product_edit_card_title"
                >
                  Link
                  <TextArea
                    id="description"
                    name="description"
                    placeholder="Link"
                    value={excerpt}
                    onChange={(e) => setExcerpt(e.target.value)}
                    style={{ marginBottom: "16px", resize: "none" }}
                    required
                  />
                </label>
              </>
              <div className="modal-footer">
                <button onClick={handleCancel}>
                  <span>Bekor qilish</span>
                </button>
                <button
                  className="product_edit_card_save"
                  onClick={updateProduct}
                >
                  <span>Saqlash</span>
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p>Siz rostdan ham ushbu mahsulotni o’chirmoqchimisiz?</p>
              <div className="modal-footer">
                <button onClick={handleCancel}>
                  <span>Bekor qilish</span>
                </button>
                <button className="delete-btn" onClick={removeProduct}>
                  <span>O'chirish</span>
                </button>
              </div>
            </div>
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default NewsCard;
