import React, { useState, useEffect } from "react";
import { Modal, Input, message, Switch, Spin } from "antd";
import { Link, useParams } from "react-router-dom";
import { DeleteIcon, Pen } from "../../assets/images/icons";
import { useUserContext } from "../../context/dashboard-context";
import axios from "axios";
import "./categorycard.scss";
import { EyeIcon } from "../../assets/images/icons";
import { Form, InputGroup } from "react-bootstrap";
import ImageUploader from "../uploader";
import { Simulate } from "react-dom/test-utils";
import ImageEditeUploader from "../imageEditeUploader";

const Categorycard = ({
  img,
  description,
  title,
  id,
  type,
  link,
  featuredproduct,
  slug,
  GetCategory,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState(title);
  const [loading, setLoading] = useState(false);
  const [ImageUrl, setUpdatedImageUrl] = useState(null);
  const [updatedSlug, setUpdatedSlug] = useState(slug);
  const [featured, setFeatured] = useState(featuredproduct);

  const { token, images } = useUserContext();
  useEffect(() => {
    if (title && slug) {
      setUpdatedTitle(title);
      setUpdatedSlug(slug);
      setFeatured(featuredproduct);
    }
  }, [title, slug]);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const showEditForm = () => {
    setIsEditMode(true);
    showModal();
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
  };

  const handleCancel = () => {
    setUpdatedImageUrl(null);
    setIsModalOpen(false);
    setIsEditMode(false);
  };

  const removeProduct = () => {
    setLoading(true);
    axios
      .delete(`https://api.protools.uz/v1/${type}/${id}`, {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        GetCategory();
        handleOk();
        setLoading(false);
        // window.location.reload()
        message.success("Product removed successfully");
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("token");
        }
        message.error("Failed to remove product");
      });
  };

  const updateProduct = () => {
    const formData = new FormData();
    setLoading(true);
    axios
      .put(
        `https://api.protools.uz/v1/categories/${id}`,
        {
          title: updatedTitle,
          image: ImageUrl ? ImageUrl : img,
          featured: featured,
          slug: updatedSlug,
          status: featured === true ? "ACTIVE" : "INACTIVE",
          // Simulate: fidfq
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        GetCategory();
        handleOk();
        setLoading(false);
        // window.location.reload()
        message.success("Mahsulot yangilandi");
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("token");
        }
        message.error("Mahsulot yangilanmadi");
      });
  };
  const [switchbutton, setSwitch] = useState(featuredproduct);

  return (
    <>
      {link ? (
        <>
          <Spin spinning={loading}>
            <div className="product-card">
              <div className="card-img">
                <img src={img} alt="" />
                <div className="card-edit">
                  <Link to={`${link}${id}`}>
                    <svg
                      style={{
                        position: "absolute",
                        left: "42%",
                        top: "6%",
                      }}
                      width="24"
                      height="24"
                      xmlns="http://www.w3.org/2000/svg"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                    >
                      <path d="M8.137 15.147c-.71-.857-1.146-1.947-1.146-3.147 0-2.76 2.241-5 5-5 1.201 0 2.291.435 3.148 1.145l1.897-1.897c-1.441-.738-3.122-1.248-5.035-1.248-6.115 0-10.025 5.355-10.842 6.584.529.834 2.379 3.527 5.113 5.428l1.865-1.865zm6.294-6.294c-.673-.53-1.515-.853-2.44-.853-2.207 0-4 1.792-4 4 0 .923.324 1.765.854 2.439l5.586-5.586zm7.56-6.146l-19.292 19.293-.708-.707 3.548-3.548c-2.298-1.612-4.234-3.885-5.548-6.169 2.418-4.103 6.943-7.576 12.01-7.576 2.065 0 4.021.566 5.782 1.501l3.501-3.501.707.707zm-2.465 3.879l-.734.734c2.236 1.619 3.628 3.604 4.061 4.274-.739 1.303-4.546 7.406-10.852 7.406-1.425 0-2.749-.368-3.951-.938l-.748.748c1.475.742 3.057 1.19 4.699 1.19 5.274 0 9.758-4.006 11.999-8.436-1.087-1.891-2.63-3.637-4.474-4.978zm-3.535 5.414c0-.554-.113-1.082-.317-1.562l.734-.734c.361.69.583 1.464.583 2.296 0 2.759-2.24 5-5 5-.832 0-1.604-.223-2.295-.583l.734-.735c.48.204 1.007.318 1.561.318 2.208 0 4-1.792 4-4z" />
                    </svg>
                  </Link>
                  <button onClick={showEditForm}>
                    <Pen />
                    O’zgartirish
                  </button>
                  <button onClick={removeProduct}>
                    <DeleteIcon />
                    O’chirish
                  </button>
                </div>
              </div>
              <Link className="card-text">
                <h1>{title}</h1>
              </Link>
            </div>
          </Spin>
        </>
      ) : (
        <div className="product-card">
          <div className="card-img">
            <img src={img} alt="" />
            <div className="card-edit">
              <button onClick={showEditForm}>
                <Pen />
                O’zgartirish
              </button>
              <button onClick={removeProduct}>
                <DeleteIcon />
                O’chirish
              </button>
            </div>
          </div>
          <Link to="/admin" className="card-text">
            <h1>{title}</h1>
          </Link>
        </div>
      )}
      <Modal
        title={isEditMode ? "Update Product" : "Delete Product"}
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Spin spinning={loading}>
          {isEditMode ? (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <div className="product_edit_card">
                <p className="product_edit_card_info">
                  Kategoriya o’zgartirish
                </p>
                <ImageEditeUploader
                  setUpdatedImage={setUpdatedImageUrl}
                  updatedImage={ImageUrl ? ImageUrl : img}
                  token={token}
                />
                <div className="my-3 d-flex justify-content-between">
                  <label>
                    {" "}
                    Top {type === "products" ? "mahsulot" : "Category"}
                  </label>
                  <Switch
                    onClick={() => setSwitch(!switchbutton)}
                    onChange={() => setFeatured(!featured)}
                    checked={switchbutton}
                  />
                </div>
                <Form.Group
                  controlId="title"
                  className="product_edit_card_title mb-5"
                >
                  <Form.Label>Nomi uz</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Title"
                      value={updatedTitle}
                      onChange={(e) => setUpdatedTitle(e.target.value)}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <Form.Group
                  controlId="title"
                  className="product_edit_card_title mb-5"
                >
                  <Form.Label>Nomi ru</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="text"
                      placeholder="Title"
                      value={updatedSlug}
                      onChange={(e) => setUpdatedSlug(e.target.value)}
                      required
                    />
                  </InputGroup>
                </Form.Group>
                <div className="modal-footer">
                  <button onClick={handleCancel}>
                    <span>Bekor qilish</span>
                  </button>
                  <button
                    className="product_edit_card_save"
                    onClick={updateProduct}
                  >
                    <span>Saqlash</span>
                  </button>
                </div>
              </div>
            </Form>
          ) : (
            <div>
              <p>Siz rostdan ham ushbu mahsulotni o’chirmoqchimisiz?</p>
              <div className="modal-footer">
                <button onClick={handleCancel}>
                  <span>Bekor qilish</span>
                </button>
                <button className="delete-btn" onClick={removeProduct}>
                  <span>O'chirish</span>
                </button>
              </div>
            </div>
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default Categorycard;
