import React, { useState, useEffect, lazy, Suspense } from "react";
import { Modal, Input, message, Select, Switch, Spin } from "antd";
import { Link } from "react-router-dom";
import { DeleteIcon, Pen } from "../../assets/images/icons";
import "./product-card.scss";
import { useUserContext } from "../../context/dashboard-context";
import axios from "axios";
import ImageEditeUploader from "../imageEditeUploader";

const { Option } = Select;

const BrandCard = ({ data, handleRefatch }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [updatedTitle, setUpdatedTitle] = useState(data?.name);
  const [updatedDescription, setUpdatedDescription] = useState(data?.body);
  const [loading, setLoading] = useState(false);
  const [updatedImage, setUpdatedImage] = useState(data?.cardImage);
  const [author, setAuthor] = useState(data?.author);
  const [categoryId, setCategoryId] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [updatedSlug, setUpdatedSlug] = useState(data?.slug);

  const {
    token,
    GetCategory,
    GetProducts,
    categorys,
    images,
    GetNews,
    GetBrands,
  } = useUserContext();
  useEffect(() => {
    if (isEditMode && categorys && categorys.length > 0) {
      setCategoryId(categorys[0]._id);
    }
  }, [isEditMode, categorys]);
  useEffect(() => {
    setUpdatedTitle(data?.name);
    setUpdatedSlug(data?.slug);
  }, [data]);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const showEditForm = () => {
    setIsEditMode(true);
    showModal();
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setIsEditMode(false);
  };

  const handleCancel = () => {
    setUpdatedImage(null);
    setIsModalOpen(false);
    setIsEditMode(false);
  };

  const removeProduct = () => {
    setLoading(true);
    axios
      .delete(`https://api.protools.uz/v1/brands/${data?._id}`, {
        headers: {
          Authorization: "Bearer " + token,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(() => {
        message.success("Brand o`chirildi");
        GetBrands();
        setLoading(false);
        handleRefatch();
      })
      .catch((err) => {
        console.error(err);
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("token");
        }
        message.error("Brand o`chirilmadi");
        setLoading(false);
      });
  };

  const updateProduct = () => {
    setLoading(true);
    const formData = new FormData();
    axios
      .put(
        `https://api.protools.uz/v1/brands/${data?._id}`,
        {
          name: updatedTitle ? updatedTitle : data?.name,
          image: updatedImage ? updatedImage : data?.image,
          // ...(!updatedSlug === data.slug &&
          //   { slug: updatedSlug }
          // )
        },
        {
          headers: {
            Authorization: "Bearer " + token,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then(() => {
        handleRefatch();
        handleOk();
        message.success("Mahsulot yangilandi");
        setUpdatedTitle("");
        setUpdatedDescription("");
        setUpdatedImage(null);
        setImageFile(null);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        if (err.response && err.response.status === 401) {
          localStorage.removeItem("token");
        }
        //
        message.error("Mahsulot yangilanmadi");
        setLoading(false);
      });
  };

  return (
    <>
      <Spin spinning={loading} delay={0}>
        <div className="product-card">
          <div className="card-img">
            <img src={data?.image} alt="" />
            <div className="card-edit">
              <button onClick={showEditForm}>
                <Pen />
                O’zgartirish
              </button>
              <button onClick={removeProduct}>
                <DeleteIcon />
                O’chirish
              </button>
            </div>
          </div>
          <Link className="card-text">
            <h1>{data?.name}</h1>
            <p>{data?.createdAt?.slice(0, 10)}</p>
            {/* <p>{new Intl.NumberFormat().format(price)} so'm</p> */}
          </Link>
        </div>
      </Spin>
      <Modal
        title={isEditMode ? "Update Product" : "Delete Product"}
        visible={isModalOpen}
        onCancel={handleCancel}
        footer={null}
      >
        <Spin spinning={loading} delay={0}>
          {isEditMode ? (
            <div className="product_edit_card">
              <p className="product_edit_card_info">Brendni o'zgartirish</p>
              <>
                <ImageEditeUploader
                  setUpdatedImage={setUpdatedImage}
                  updatedImage={updatedImage ? updatedImage : data?.image}
                  token={token}
                />
                <label htmlFor="title" className="product_edit_card_title">
                  Nomi uz
                  <Input
                    id="title"
                    name="title"
                    placeholder="Nomi"
                    value={updatedTitle}
                    onChange={(e) => setUpdatedTitle(e.target.value)}
                    style={{ marginBottom: "16px" }}
                    required
                  />
                </label>
              </>
              <div className="modal-footer">
                <button onClick={handleCancel}>
                  <span>Bekor qilish</span>
                </button>
                <button
                  className="product_edit_card_save"
                  onClick={updateProduct}
                >
                  <span>Saqlash</span>
                </button>
              </div>
            </div>
          ) : (
            <div>
              <p>Siz rostdan ham ushbu mahsulotni o’chirmoqchimisiz?</p>
              <div className="modal-footer">
                <button onClick={handleCancel}>
                  <span>Bekor qilish</span>
                </button>
                <button className="delete-btn" onClick={removeProduct}>
                  <span>O'chirish</span>
                </button>
              </div>
            </div>
          )}
        </Spin>
      </Modal>
    </>
  );
};

export default BrandCard;
