import { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Spin } from "antd";
import { useUserContext } from "../context/dashboard-context";
import axios from "axios";
import ImageUploader from "../components/uploader";
import "./news-page.scss";
import NewsCard from "../components/product-card/news-card";
import CustomPagination from "../components/pagination";
import { useContext } from "react";
import ImageUploadContext from "../context/ImageUploadContext";

const NewsPage = () => {
  const { clearImages, images } = useContext(ImageUploadContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { token, setRefresh, fileList, setFileList } = useUserContext();
  const [news, setNews] = useState();
  const [loading, setLoading] = useState(false);
  const GetNews = (fuckingPageNumber) => {
    setLoading(true);
    axios
      .get(
        `https://api.protools.uz/v1/articles?limit=12?page=${
          fuckingPageNumber ? fuckingPageNumber : 1
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setNews(response?.data?.data);
        setTotalProducts(response?.data?.totalCount);
        setPagesCount(response?.data?.pagesCount);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const [totalProducts, setTotalProducts] = useState(0);
  const [pagesCount, setPagesCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(
    JSON.parse(localStorage.getItem("page")),
    1
  );
  const showModal = () => {
    setIsModalOpen(true);
    setClear(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const [clear, setClear] = useState(false);
  const handleCancel = () => {
    setIsModalOpen(false);
    clearImages();
    setFileList([]);
    form.resetFields();
  };

  useEffect(() => {
    GetNews();
  }, []);

  const [form] = Form.useForm();
  const [title, setTitle] = useState();
  const [body, setBody] = useState();
  const [author, setAuthor] = useState();
  const [excerpt, setExcerpt] = useState();

  const refreshPage = () => {
    setRefresh((value) => !value);
  };
  const onFinish = () => {
    setLoading(true);
    const requestData = {
      title: title,
      body: body,
      author: author,
      excerpt: excerpt,
      cardImage: images[0],
    };
    axios
      .post("https://api.protools.uz/v1/articles", requestData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        form.resetFields();
        console.log(response.data);
        GetNews();
        setClear(true);
        clearImages();
        setFileList([]);
        if (response.status === "201") {
          refreshPage();
        }

        handleOk();
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  };
  const GetSearch = (value) => {
    axios
      .get(`https://api.protools.uz/v1/articles?search=${value}`)
      .then((response) => {
        setNews(response?.data?.data);
        setTotalProducts(response?.data?.totalCount);
      });
  };
  return (
    <>
      <Spin spinning={loading}>
        <div className="news-page">
          <div className="page-top">
            <h1>Yangiliklar</h1>
            <button onClick={showModal}>+ Yangilik qo’shish</button>
          </div>
          <div className="mb-4">
            <Input
              onChange={(e) => GetSearch(e.target.value)}
              placeholder="Qidirish"
            />
          </div>
          <div className="page-cards">
            {news?.map((i, k) => (
              <NewsCard GetNews={GetNews} data={i} />
            ))}
          </div>
          <CustomPagination
            setCurrentPage={setCurrentPage}
            func={GetNews}
            current={currentPage}
            defaultCurrent={1}
            total={totalProducts}
          />
        </div>
      </Spin>
      <div className="modal-news">
        <Modal
          title="Mahsulot qo’shish"
          open={isModalOpen}
          onCancel={handleCancel}
        >
          <Spin spinning={loading}>
            <Form
              variant="filled"
              form={form}
              layout="vertical"
              onFinish={() => {
                onFinish();
              }}
            >
              <div className="body-img">
                <ImageUploader setClear={setClear} clear={clear} />
                <p
                  style={{
                    color: "red",
                  }}
                >
                  {/* {fileList.length == 0 && "Rasm tanlash majburiy"} */}
                </p>
              </div>

              <Form.Item
                label="Nomi"
                name="title"
                rules={[{ required: true, message: "Nomi is required" }]}
              >
                <Input
                  placeholder="Nomir"
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                label="Matn uz"
                name="description"
                rules={[{ required: true, message: "Description is required" }]}
              >
                <Input
                  placeholder="Description"
                  onChange={(e) => setBody(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                label="Matn ru"
                name="author"
                rules={[{ required: true, message: "Author is required" }]}
              >
                <Input
                  placeholder="author"
                  onChange={(e) => setAuthor(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                label="Link"
                name="excerpt"
                rules={[{ required: true, message: "Link is required" }]}
              >
                <Input
                  className="w-100"
                  placeholder="Link"
                  onChange={(e) => setExcerpt(e.target.value)}
                />
              </Form.Item>

              <div className="modal-footer">
                <button onClick={handleCancel}>
                  <span>Bekor qilish</span>
                </button>
                <Button htmlType="submt">Qo'shish</Button>
              </div>
            </Form>
          </Spin>
        </Modal>
      </div>
    </>
  );
};
export default NewsPage;
