import { useEffect, useState } from "react";
import { Button, Form, Input, Modal, Pagination, Spin } from "antd";
import { message } from "antd";
import axios from "axios";
import { useUserContext } from "../context/dashboard-context";
import SearchInput from "../components/search";
import Categorycard from "../components/categorycard/Categorycard";
import "./news-page.scss";
import ImageUploader from "../components/uploader";
import CustomPagination from "../components/pagination";
import { useParams } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useImageUpload } from "../context/ImageUploadContext";

const CategoriesPage = () => {
  const [form] = Form.useForm();
  const { token, loading, setLoading } = useUserContext();
  const { images, clearImages } = useImageUpload();
  const [categorys, setCategorys] = useState();
  const [clear, setClear] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    JSON.parse(localStorage.getItem("page")),
    1
  );
  const [totalProducts, setTotalProducts] = useState(0);
  const [pagesCount, setPagesCount] = useState(0);
  // const page  = useParams()
  const [searchParams, setSearchParams] = useSearchParams();

  const GetCategory = (page = currentPage) => {
    setLoading(true);
    axios
      .get(
        `https://api.protools.uz/v1/categories?limit=12?sortBy=createdAt&orderBy=DESC&page=${page}`,
        {}
      )
      .then((response) => {
        console.log(response, "misha");
        setLoading(false);
        setCategorys(response?.data?.data);
        setTotalProducts(response?.data?.totalCount);
        setPagesCount(response?.data?.pagesCount);
      });
  };

  const [value, setValue] = useState();
  const [slug, setSlug] = useState();
  const [imageUrl, setImageUrl] = useState();
  const [img, setImg] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    clearImages([]);
    setIsModalOpen(true);
    form.resetFields();
  };

  const handleOk = () => {
    setIsModalOpen(false);
    setValue("");
    setImageUrl(null);
    setImg(null);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
    clearImages([]);
  };

  const AddCategory = async () => {
    setLoadingCreate(true);
    try {
      const response = await axios.post(
        "https://api.protools.uz/v1/categories",
        {
          title: value,
          slug: slug,
          image: images[0],
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (response.status === 200 || response.status === 201) {
        setLoadingCreate(false);
        console.log("succes");
        form.resetFields();
        // window.location.reload()
        GetCategory();
      }
    } catch (error) {
      console.error(error);
      setLoadingCreate(false);

      if (
        error.response &&
        error.response.data &&
        error.response.data.code === "CATEGORY_NOT_FOUND"
      ) {
        console.log("Category not found");
      } else {
        console.log(error.response?.data.data);
      }
    }
  };

  useEffect(() => {
    GetCategory(currentPage);
  }, [img, currentPage]);

  const GetSearch = (value) => {
    axios
      .get(`https://api.protools.uz/v1/categories?search=${value}`)
      .then((response) => {
        setCategorys(response?.data?.data);
        setTotalProducts(response?.data?.totalCount);
      });
  };
  return (
    <>
      <Spin spinning={loading}>
        <div className="news-page">
          <div className="page-top">
            <h1>Kategoriyalar</h1>
            <button onClick={showModal}>+ Kategoriya qo’shish</button>
          </div>
          <div className="mb-4">
            <Input
              onChange={(e) => GetSearch(e.target.value)}
              placeholder="Qidirish"
            />
          </div>
          <div className="page-cards">
            {categorys?.map((i, k) => (
              <Categorycard
                GetCategory={GetCategory}
                featuredproduct={i?.featured}
                type={"categories"}
                link={`/admin/category/`}
                id={i._id}
                slug={i?.slug}
                img={i.image}
                title={i.title}
              />
            ))}
          </div>
          <CustomPagination
            func={GetCategory}
            current={currentPage}
            defaultCurrent={1}
            total={totalProducts}
            setCurrentPage={setCurrentPage}
            // onChange={handlePageChange}
          />
        </div>
      </Spin>
      <div className="modal-news">
        <Modal
          title="Kategoriya qo’shish"
          open={isModalOpen}
          onCancel={handleCancel}
        >
          <Spin spinning={loadingCreate}>
            <Form
              form={form}
              onFinish={(e) => {
                AddCategory();
                handleOk();
                e.preventDefault();
              }}
              layout="vertical"
            >
              <div className="body-img">
                <ImageUploader clear={true} disabled={true} />
              </div>

              <Form.Item
                label="Nomi uz"
                name="name"
                rules={[{ required: true, message: "Majburiy !" }]}
              >
                <Input
                  onChange={(e) => setValue(e.target.value)}
                  placeholder="nomi"
                />
              </Form.Item>

              <Form.Item
                label="Nomi ru"
                name="nameru"
                rules={[{ required: true, message: "Majburiy !" }]}
              >
                <Input
                  onChange={(e) => setSlug(e.target.value)}
                  placeholder="nomi ru"
                />
              </Form.Item>

              <div className="modal-footer">
                <Button onClick={handleCancel}>
                  <span>Bekor qilish</span>
                </Button>
                <Button type="primary" htmlType="submit">
                  <span>Qo'shish</span>
                </Button>
              </div>
            </Form>
          </Spin>
        </Modal>
      </div>
    </>
  );
};
export default CategoriesPage;
