import { createContext, useContext, useEffect, useState } from "react";
import axios from "axios";
import config from "../config/config";
import { useParams } from "react-router";

const UserContext = createContext();

export const UserContextProvider = ({ children }) => {
  //   const [users, setUsers] = useState(JSON.parse(localStorage.getItem("user")));
  const [token, setToken] = useState(JSON.parse(localStorage.getItem("token")));
  const [lang, setLang] = useState(localStorage.getItem("lang"));
  const [categorys, setCategorys] = useState();
  const [categorysbyid, setCategorysByid] = useState();
  const [loading, setLoading] = useState(false);
  const [courseById, setCourseById] = useState("Barchasi");
  const [categoryId, setCategoryId] = useState(null);
  const [products, setProducts] = useState();
  const [zapchastproducts, setZapchastProducts] = useState();
  const [active, setActive] = useState("Barchasi");
  const [user, setUser] = useState();
  const [productImages, setProductImages] = useState();
  const [images, setImages] = useState([]);
  const [password, setPassword] = useState();
  const [username, setUsername] = useState();
  const [similar, setSimilar] = useState();
  const [fileList, setFileList] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState();
  const [data, setData] = useState();
  const [parentid, setParentId] = useState();
  const [category, setCategory] = useState();
  const [subcategory, setSubcategory] = useState();
  const [news, setNews] = useState();
  const [banners, setBanners] = useState();
  const [brands, setBrands] = useState();
  const [countPage, setCountPage] = useState();
  const [showimg, setShowimg] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    JSON.parse(localStorage.getItem("page")),
    1
  );
  const [totalProducts, setTotalProducts] = useState(0);
  const [pagesCount, setPagesCount] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [order, setOrder] = useState();
  // const GetCategory = () => {
  //   axios.get(`https://api.protools.uz/v1/categories`).then((response) => {
  //     // setTotal(JSON.parse(response.headers["x-pagination"]));
  //     setCategory(response?.data?.data);
  //     console.log(response);
  //   });
  // };

  const GetCategory = (fuckingPageNumber = 1) => {
    setLoading(true);
    axios
      .get(
        `https://api.protools.uz/v1/categories?sortBy=createdAt&orderBy=DESC&page=${fuckingPageNumber}`,
        {}
      )
      .then((response) => {
        setLoading(false);
        setCategorys(response?.data?.data);
        setTotalProducts(response?.data?.totalCount);
        setPagesCount(response?.data?.pagesCount);
      });
  };

  const GetOrders = (fuckingPageNumber) => {
    setLoading(true);
    axios
      .get(
        `https://api.protools.uz/v1/categories?sortBy=createdAt&orderBy=DESC&page=${
          fuckingPageNumber ? fuckingPageNumber : 1
        }`,
        {}
      )
      .then((response) => {
        console.log(response, "resp");
        setOrder(response.data);
        setTotalProducts(response?.data?.totalCount);
        setPagesCount(response?.data?.pagesCount);
        setLoading(false);
      });
  };

  const GetSubCategory = (fuckingPageNumber) => {
    setLoading(true);
    axios
      .get(
        `https://api.protools.uz/v1/categories?page=${
          fuckingPageNumber ? fuckingPageNumber : 1
        }`,
        {}
      )
      .then((response) => {
        setLoading(false);
        setSubcategory(response?.data?.data);
        setTotalProducts(response?.data?.totalCount);
        setPagesCount(response?.data?.pagesCount);
      });
  };
  const GetProducts = (fuckingPageNumber) => {
    setLoading(true);
    axios
      .get(
        `https://api.protools.uz/v1/products?limit=12&page=${
          fuckingPageNumber ? fuckingPageNumber : 1
        }`,
        {}
      )
      .then((response) => {
        setLoading(false);
        setProducts(response?.data?.data);
        setTotalProducts(response?.data?.totalCount);
        setPagesCount(response?.data?.pagesCount);
      });
  };

  const GetBrands = (fuckingPageNumber) => {
    setLoading(true);
    axios
      .get(
        `https://api.protools.uz/v1/brands?page=${
          fuckingPageNumber ? fuckingPageNumber : 1
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setLoading(false);
        setBrands(response?.data?.data);
        setTotalProducts(response?.data?.totalCount);
        setPagesCount(response?.data?.pagesCount);
      });
  };

  useEffect(() => {
    // if (user) {
    //     localStorage.setItem("user", JSON.stringify(user))
    // } if (!user) {
    //     localStorage.removeItem("user")
    // }
    if (token) {
      localStorage.setItem("token", JSON.stringify(token));
    }
    if (currentPage) {
      localStorage.setItem("page", JSON.stringify(currentPage));
    }
    if (!token) {
      localStorage.removeItem("token");
    }
    if (lang) {
      localStorage.setItem("lang", lang);
    }
    if (!lang) {
      localStorage.removeItem("lang");
    }
  }, [token, lang, currentPage]);

  const GetNews = (fuckingPageNumber) => {
    axios
      .get(
        `https://api.protools.uz/v1/articles?page=${
          fuckingPageNumber ? fuckingPageNumber : 1
        }`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setNews(response?.data?.data);
        setTotalProducts(response?.data?.totalCount);
        setPagesCount(response?.data?.pagesCount);
      });
  };
  const GetBanners = (fuckingPageNumber) => {
    axios
      .get(
        `https://api.protools.uz/v1/banners?page=${
          fuckingPageNumber ? fuckingPageNumber : 1
        }`
      )
      .then((response) => {
        setBanners(response?.data?.data);
        setTotalProducts(response?.data?.totalCount);
        setPagesCount(response?.data?.pagesCount);
      });
  };
  const pageLocation = window.location.pathname;
  useEffect(() => {
    GetBrands();
    GetProducts();
    GetCategory();
    GetNews();
    GetBanners();
    GetOrders();
  }, [pageLocation]);

  // const AddBanner = (bannerData) => {
  //   axios
  //     .post('https://api.protools.uz/v1/banners', bannerData, {
  //       headers: { Authorization: `Bearer ${token}` },
  //     })
  //     .then((response) => {
  //       console.log(response.data);
  //       GetBanners();
  //       window.location.reload();
  //     })
  //     .catch((error) => {
  //       console.error('Error adding banner:', error);
  //       if (error.response && error.response.status === 401) {
  //         localStorage.removeItem('token');
  //       }
  //     });
  // };

  const value = {
    // users,
    // setUsers,
    token,
    setToken,
    loading,
    setLoading,
    categorys,
    setCategorys,
    products,
    setProducts,
    courseById,
    setCourseById,
    active,
    setActive,
    user,
    setUser,
    productImages,
    setProductImages,
    categoryId,
    totalProducts,
    setTotalProducts,
    refresh,
    setRefresh,
    setCategorysByid,
    categoryId,
    setCategoryId,
    categorysbyid,
    currentPage,
    setCurrentPage,
    setCategorysByid,
    brands,
    setBrands,
    images,
    setImages,
    password,
    setPassword,
    username,
    setUsername,
    similar,
    setSimilar,
    lang,
    setLang,
    page,
    setPage,
    total,
    setTotal,
    data,
    setData,
    parentid,
    category,
    setCategory,
    setParentId,
    GetCategory,
    news,
    setNews,
    GetProducts,
    GetNews,
    banners,
    setBanners,
    GetBanners,
    subcategory,
    setSubcategory,
    GetBrands,
    GetSubCategory,
    countPage,
    setCountPage,
    pagesCount,
    setPagesCount,
    order,
    setOrder,
    zapchastproducts,
    setZapchastProducts,
    GetOrders,
    showimg,
    setShowimg,
    fileList,
    setFileList,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
